import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Flex from "./Flex"
import { graphql } from "gatsby"
import Box from "./Box"
import useBreakpoint from "../../hooks/use-breakpoint"

const reverseFlexDirection = flexDirection => {
  const reverseFlexDirectionString = flexDirection => {
    return flexDirection.includes("reverse")
      ? flexDirection.splice("-reverse")[0]
      : flexDirection + "-reverse"
  }
  if (typeof flexDirection === "Object") {
    for (const [key, value] in flexDirection) {
      flexDirection[key] = reverseFlexDirectionString(flexDirection[key])
    }
  } else {
    flexDirection = reverseFlexDirectionString(flexDirection)
  }
  return flexDirection
}

const LayoutChanger = ({
  location,
  align,
  child1Px,
  children,
  itemsMoveStackedOnColumn = true,
  child1Style,
  child2Style,
  reversed,
  marginBtwChilds,
  child1FlexDirOnMobile,
  ...rest
}) => {
  align = !align ? "start" : align
  location = !location ? "left" : location

  const defaultMargin =
    marginBtwChilds || marginBtwChilds === 0 ? marginBtwChilds : 7

  let flexDirection,
    justifyContent,
    alignItems,
    width,
    child1Mt,
    child2Mt,
    child2Ml,
    child2Mr

  const [child1, child2] = children
  alignItems =
    align && align !== "center"
      ? `flex-${align}`
      : align && align === "stretch"
      ? "stretch"
      : "center"
  alignItems = { _: "center", lg: alignItems }
  if (child2) {
    if (location === "left" || location === "right") {
      justifyContent = { _: "flex-start", lg: "space-between" }
      width = { _: "100%", lg: "calc(50% - 32px)" }

      if (location === "left") {
        child2Ml = { _: 0, lg: defaultMargin }
        flexDirection = {
          _: child1FlexDirOnMobile || "column",
          md: "row",
        }
      } else {
        child2Mr = { _: 0, lg: defaultMargin }
        flexDirection = {
          _: child1FlexDirOnMobile || "column-reverse",
          md: "row-reverse",
        }
      }
      child1Mt = {
        _:
          !child1FlexDirOnMobile || child1FlexDirOnMobile === "column-reverse"
            ? defaultMargin
            : 0,
        lg: 0,
      }
      child2Mt = {
        _:
          !child1FlexDirOnMobile || child1FlexDirOnMobile === "column"
            ? defaultMargin
            : 0,
        lg: 0,
      }
    } else if (location === "top" || location === "bottom") {
      width = "100%"
      child2Ml = 0
      child2Mr = 0
      if (location === "bottom") {
        flexDirection = {
          _: child1FlexDirOnMobile || "column-reverse",
          md: "column-reverse",
        }
      } else {
        flexDirection = {
          _: child1FlexDirOnMobile || "column",
          md: "column",
        }
      }
      child1Mt = {
        _:
          !child1FlexDirOnMobile || child1FlexDirOnMobile === "column-reverse"
            ? 7
            : 0,
        md: location === "bottom" ? 7 : 0,
      }
      child2Mt = {
        _: !child1FlexDirOnMobile || child1FlexDirOnMobile === "column" ? 7 : 0,
        md: location === "top" ? 7 : 0,
      }
    }
  } else {
    width = "100%"
  }

  if (reversed) flexDirection = reverseFlexDirection(flexDirection)

  const mobileFlexColumn = String(flexDirection?.["_"]).includes("column")
  const mdFlexColumn = String(flexDirection?.["md"]).includes("column")
  const flexDirColumn =
    (useBreakpoint("md") && mobileFlexColumn) || mdFlexColumn

  const child1Ref = useRef(null)
  const [child1Width, setChild1Width] = useState(null)
  useEffect(() => {
    setChild1Width(child1Ref?.current?.clientWidth)
  }, [child1Ref?.current?.clientWidth])

  if (!children) return null

  return (
    <Flex
      justifyContent={!child2 ? alignItems : justifyContent}
      flexDirection={flexDirection}
      alignItems={alignItems}
      {...rest}
    >
      {child1 && (
        <Box
          ref={child1Ref}
          display="flex"
          className="child1"
          mt={child1Mt}
          justifyContent={flexDirColumn || !child2 ? alignItems : null}
          flex={1}
          maxWidth={
            child1Px > 0 && (location === "left" || location === "right")
              ? "50%"
              : "none"
          }
          minWidth={
            flexDirColumn || !child2
              ? { _: "100%", md: 35 }
              : { _: "100%", md: "50%" }
          }
          px={{ _: null, lg: `${child1Px}px` }}
          {...child1Style}
        >
          {child1}
        </Box>
      )}
      {child2 && (
        <Box
          display="flex"
          className="child2"
          flex={1}
          justifyContent={
            !itemsMoveStackedOnColumn && flexDirColumn
              ? "center"
              : flexDirColumn
              ? alignItems
              : null
          }
          maxWidth={
            itemsMoveStackedOnColumn && flexDirColumn
              ? child1Width || 44
              : flexDirColumn
              ? width
              : "100%"
          }
          width="100%"
          mt={child2Mt}
          mr={child2Mr}
          ml={child2Ml}
          {...child2Style}
        >
          {child2}
        </Box>
      )}
    </Flex>
  )
}

LayoutChanger.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
}

export const query = graphql`
  fragment LayoutChanger on Strapi_ComponentAtomsLayoutChanger {
    id
    align
    location
    child1Px
  }
`

export default LayoutChanger
