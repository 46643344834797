import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"

import ButtonElement from "../atoms/ButtonElement"
import Flex from "../atoms/Flex"
import PopUpMenu from "../atoms/PopUpMenu"
import { Text } from "../atoms/Typography"

import Caret from "../icons/Caret"

const Filter = ({
  name,
  allLabel,
  center,
  tags,
  onChange,
  filterItems,
  alignMenuItems,
  card,
}) => {
  const [selected, setSelected] = useState(allLabel)
  const [menuOpen, setMenuOpen] = useState(false)
  const [options, setOptions] = useState([])
  const anchorRef = useRef(null)

  useEffect(() => {
    onChange(selected === allLabel ? null : selected)
  }, [filterItems, selected])

  useEffect(() => {
    let newOptions = []
    if (allLabel) newOptions = [allLabel]
    if (tags) newOptions = [...newOptions, ...tags.map(({ name }) => name)]
    setOptions(newOptions)
  }, [allLabel, tags])

  return (
    <>
      <ButtonElement ref={anchorRef} onClick={() => setMenuOpen(true)} mt={4}>
        <Flex minWidth={8} alignItems="center" justifyContent="space-between">
          <Text fontSize={1} color="text">
            {selected}
          </Text>
          <Caret color="text" />
        </Flex>
      </ButtonElement>
      <PopUpMenu
        center={center}
        anchorEl={anchorRef.current}
        show={menuOpen}
        active={selected}
        onSelect={value => {
          setSelected(value)
        }}
        handleClose={() => setMenuOpen(false)}
        options={options}
        card={card}
        alignMenuItems={alignMenuItems}
      />
    </>
  )
}

export default Filter

export const query = graphql`
  fragment Filter on Strapi_ComponentAtomsFilter {
    name
    allLabel
    tags {
      name
    }
  }
`
