import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Image from "../utils/Image"
import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"
import LayoutChanger from "../atoms/LayoutChanger"

import SectionTitle from "../molecules/SectionTitle"
import Box from "../atoms/Box"

import StandardPadding from "../atoms/StandardPadding"

const ImageText = ({
  section,
  sectionTitle,
  button,
  button2,
  image,
  layoutChanger,
  imagePositioning,
  imagePositioningOnMobile,
}) => {
  imagePositioning = imagePositioning === null ? "floating" : imagePositioning

  const imagePositioningNotFloating =
    imagePositioning && imagePositioning !== "floating"
  const defaultPadding =
    section.customVerticalPadding > 0 &&
    Math.floor(section.customVerticalPadding / 32) >= 2
      ? section.customVerticalPadding - 16
      : 32
  const customPadding = {
    _: section.verticalPadding === "custom" ? `${defaultPadding}px` : 7,
    lg: `${section.customVerticalPadding}px`,
  }
  const conditionalProps = {
    py: customPadding,
  }
  if (imagePositioning === "floating" || section.verticalPadding !== "custom") {
    conditionalProps.disabled = true
  }

  return (
    <Section
      {...section}
      noStandardPadding={imagePositioningNotFloating ? true : false}
      customVerticalPadding={
        imagePositioningNotFloating ? -1 : section.customVerticalPadding
      }
      maxWidth={imagePositioning === "fit" ? "100%" : section.maxWidth}
      margin="0px auto"
    >
      <LayoutChanger
        itemsMoveStackedOnColumn={true}
        child1Style={{
          zIndex: 10,
          paddingLeft:
            imagePositioningNotFloating && layoutChanger.location === "right"
              ? "0px"
              : null,
          alignSelf:
            imagePositioning !== "floating"
              ? "center"
              : layoutChanger.align === "center"
              ? "center"
              : `flex-${layoutChanger.align}`,
          width:
            layoutChanger.location === "right" ||
            layoutChanger.location === "left"
              ? "100%"
              : "inherit",
        }}
        child2Style={{
          zIndex: 12,
          alignSelf: imagePositioningNotFloating
            ? { _: "center", lg: "stretch" }
            : layoutChanger.align,
          width: "100%",
        }}
        {...layoutChanger}
        marginBtwChilds={imagePositioningNotFloating ? 0 : 7}
        reverse={true}
        child1FlexDirOnMobile={
          imagePositioningOnMobile === "top" ? "column-reverse" : "column"
        }
      >
        <Reveal height="100%" width="100%">
          <StandardPadding
            verticalPadding={{
              _: imagePositioningNotFloating ? "default" : "custom",
              md:
                imagePositioningNotFloating && section.verticalPadding
                  ? section.verticalPadding
                  : imagePositioningNotFloating
                  ? "default"
                  : "custom",
            }}
            {...conditionalProps}
            px={
              imagePositioning === "fit"
                ? { _: 5, lg: 7, xl: 8 }
                : imagePositioning === "fitToHeight"
                ? null
                : { _: 5, md: 7, lg: 0 }
            }
            pl={
              imagePositioning === "fitToHeight" &&
              layoutChanger.location === "left"
                ? { _: 5, lg: 7, xl: 4 }
                : imagePositioning === "fitToHeight"
                ? { _: 5, lg: 7 }
                : null
            }
            pr={
              imagePositioning === "fitToHeight" &&
              layoutChanger.location === "right"
                ? { _: 5, lg: 7, xl: 4 }
                : imagePositioning === "fitToHeight"
                ? { _: 5, lg: 7 }
                : null
            }
            position="relative"
            zIndex={10}
            width="100%"
            height="100%"
          >
            <Box
              width={imagePositioningNotFloating ? "100%" : "inherit"}
              height="100%"
              minHeight={
                imagePositioningNotFloating
                  ? { _: "100%", lg: "350px" }
                  : "100%"
              } // adding height to text box to make vertical padding slider work better
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {sectionTitle && <SectionTitle maxWidth={44} {...sectionTitle} />}
              <Flex
                flexDirection={{ _: "column", md: "row" }}
                justifyContent={
                  sectionTitle?.align === "center" ? "center" : "flex-start"
                }
                alignItems={
                  sectionTitle?.align === "center" ? "center" : "flex-start"
                } //needed for mobile alignment
              >
                {button && (
                  <Button
                    mt={5}
                    mr={{ _: 0, md: button2 ? 5 : 0 }}
                    {...button}
                  />
                )}
                {button2 && <Button mt={5} {...button2} />}
              </Flex>
            </Box>
          </StandardPadding>
        </Reveal>

        {image && (
          <Box
            position="relative"
            width="100%"
            display="flex"
            justifyContent={
              imagePositioningNotFloating && layoutChanger.location === "right"
                ? "flex-end"
                : imagePositioningNotFloating &&
                  layoutChanger.location === "left"
                ? "flex-start"
                : "center"
            }
            height={
              imagePositioning === "fit" || imagePositioning === "fitToHeight"
                ? "100%"
                : "auto"
            }
            pt={{
              _:
                imagePositioningNotFloating &&
                imagePositioningOnMobile === "top"
                  ? customPadding._
                  : null,
              md: "inherit",
            }} // adds padding at mobile breakpoint since there is no standard padding when imagePositioningNotFloating
            pb={{
              _:
                imagePositioningNotFloating &&
                imagePositioningOnMobile === "bottom"
                  ? customPadding._
                  : null,
              md: "inherit",
            }} // adds padding at mobile breakpoint since there is no standard padding when imagePositioningNotFloating
          >
            <Box
              position="relative"
              height="100%"
              width="100%"
              maxWidth={imagePositioningNotFloating ? "100%" : 44}
            >
              <Reveal height="100%" width="100%">
                <Image style={{ width: "100%", height: "100%" }} {...image} />
              </Reveal>
            </Box>
          </Box>
        )}
      </LayoutChanger>
    </Section>
  )
}

export default ImageText

ImageText.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  button: PropTypes.shape(Button.strapiProps),
  image: PropTypes.object,
  ...LayoutChanger.strapiProps,
}

export const query = graphql`
  fragment ImageText on Strapi_ComponentSectionsImageText {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    button {
      ...Button
    }
    button2 {
      ...Button
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    layoutChanger {
      ...LayoutChanger
    }
    imagePositioning
    imagePositioningOnMobile
  }
`
