import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Section from "../atoms/Section"
import Card from "../atoms/Card"
import LayoutChanger from "../atoms/LayoutChanger"
import Box from "../atoms/Box"
import Image from "../utils/Image"

import SectionTitle from "../molecules/SectionTitle"
import FormIntegration from "../integrations/FormIntegration"

// Backup if site was created before card option
const defaultCard = {
  borderRadius: "lg",
  boxShadow: "lg",
  bg: 0,
  theme: "default",
  useCard: true,
}

const defaultCaptcha = {
  useCaptcha: true,
  size: "normal", //normal, invisible or compact
  theme: "dark", //light, dark
  // badge =bottomright {bottomright, bottomleft or inline}. Positions reCAPTCHA badge. Only for invisible reCAPTCHA
}

const ContactForm = ({
  section,
  sectionTitle,
  card = defaultCard,
  integration,
  layoutChanger,
  image,
  captcha,
}) => {
  captcha = captcha === null ? defaultCaptcha : captcha

  return (
    <Section {...section}>
      <LayoutChanger
        itemsMoveStackedOnColumn={true}
        child1Style={
          integration &&
          integration.type === "form" && {
            justifyContent:
              sectionTitle.align === "center" ? "center" : "flex-start",
          }
        }
        {...layoutChanger}
      >
        <Box maxWidth={44} width="100%">
          {sectionTitle && <SectionTitle {...sectionTitle} />}
          {image && <Image {...image} />}
        </Box>
        {integration && integration.type === "form" && (
          <Card
            {...card}
            padding={card.useCard ? { _: 5, md: 6 } : 0}
            maxWidth={44}
            width="100%"
          >
            <FormIntegration {...integration} captcha={captcha} />
          </Card>
        )}
      </LayoutChanger>
    </Section>
  )
}

ContactForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  integration: PropTypes.shape(FormIntegration.strapiProps),
  ...LayoutChanger.strapiProps,
}

export default ContactForm

export const query = graphql`
  fragment ContactForm on Strapi_ComponentSectionsContactForm {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    card {
      ...Card
    }
    captcha {
      ...Captcha
    }
    integration {
      ...FormIntegration
    }
    layoutChanger {
      ...LayoutChanger
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`
