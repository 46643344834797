import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { compose, layout } from "styled-system"
import { animation } from "../../lib/styled-system/system"

import Link from "../utils/Link"
import Image from "../utils/Image"
import { hexToRGBA } from "../../lib/color-helpers"

import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Card from "../atoms/Card"
import ProportionalBox from "../atoms/ProportionalBox"
import { Label, Title } from "../atoms/Typography"
import RichText from "../atoms/RichText"
import Section from "../atoms/Section"

const TextBox = styled(
  ({
    title,
    label,
    cardTitleVariant,
    cardTitleColor,
    descriptionRichText,
    className,
    textClassName,
    textAlign,
    button,
    ...rest
  }) => {
    return (
      <Box
        display={textAlign === "center" ? "flex" : "block"}
        flexDirection="column"
        justifyContent="flex-start"
        width="100%"
        textAlign={textAlign === "center" ? "center" : "left"}
        className={className}
        {...rest}
      >
        {label && (
          <Label className={textClassName} mb={3}>
            {label}
          </Label>
        )}
        <Title
          transitionProperty="opacity"
          transitionTimingFunction="ease"
          transitionDuration="md"
          className={textClassName}
          variant={cardTitleVariant === "h1" ? "h1" : "h2"}
          as={cardTitleVariant === "h1" ? "h1" : "h2"}
          color={cardTitleColor}
          mb={3}
        >
          {title}
        </Title>
        {descriptionRichText?.content && (
          <StyledRichText
            transitionProperty="opacity"
            transitionTimingFunction="ease"
            transitionDuration="md"
            className={textClassName}
            textAlign={textAlign}
            {...descriptionRichText}
          />
        )}
        {button && (
          <Button
            className={textClassName}
            style={{ zIndex: 10, position: "relative" }}
            mt={4}
            mb={4}
            {...button}
          />
        )}
      </Box>
    )
  }
)`
  ${animation}
`

const StyledLink = styled(Link)`
  &:hover {
    & .image-container {
      //getting scale on image-container or image to avoid issue with image overflowing;
      ${props =>
        props.textBackground === "gradient" && props.imageWidth === "full"
          ? "transform: scale(1.05)"
          : null};

      & .image {
        ${props =>
          props.textBackground === "solid" || props.imageWidth === "default"
            ? "transform: scale(1.05)"
            : null};
      }
    }
  }
`

const StyledCard = styled(Card)`
  & .shaved-text {
    opacity: ${props => (props.textVisibility !== "onHover" ? 1 : 0)};
  }
  &:hover {
    & .shaved-text {
      ${props => (props.textVisibility === "onHover" ? "opacity: 1" : null)};
    }
    & .shaved-text {
      opacity: ${props => (props.textVisibility === "onHover" ? 1 : 0.75)};
    }
  }
`

const StyledRichText = styled(RichText)`
  &.shaved-text {
    & .RichEditor-unstyled {
      margin-bottom: 0px; // removing unwanted margin from richText
    }
  }
  ${animation}
`

const StyledImage = styled(Image)`
  ${compose(layout)}
`

const defaultCard = {
  borderRadius: "none",
  boxShadow: "md",
  bg: 0,
  theme: "light",
  useCard: false,
}

const BigImageCard = ({
  title,
  label,
  descriptionRichText,
  image,
  link,
  alignText,
  card,
  cardTitle,
  textBackground,
  textVisibility,
  backgroundImageDesktop,
  backgroundImageMobile,
  button,
  overlayOpacity,
  imageWidth,
  imageRatio,
}) => {
  //setting a default value for card prop
  card =
    card && card.constructor === Object && Object.keys(card).length > 0
      ? card
      : defaultCard

  const currentTheme = useContext(ThemeContext)
  const bgColor = card
    ? currentTheme?.themes[card?.theme]?.colors?.background[card?.bg]
    : "#FFFFFF"
  const bgStartColor = hexToRGBA(bgColor, 100)
  const bgMidColor = hexToRGBA(bgColor, 90)
  const bgEndColor = hexToRGBA(bgColor, 0)
  const solidColorWithOpacity = hexToRGBA(bgColor, overlayOpacity * 100) //150)

  let showText = textVisibility !== "none" && card.useCard && bgColor
  let showGradient = textBackground === "gradient" && showText
  const hasDescription = descriptionRichText?.content?.blocks[0]?.text?.length
    ? true
    : false

  {
    /*TODO:create a wrapper for card background image indiviual instance */
  }

  return (
    <StyledLink
      optional
      {...link}
      textBackground={textBackground}
      imageWidth={imageWidth}
    >
      <StyledCard
        {...card}
        bg={null} //avoiding card to set bg color
        position="relative"
        textVisibility={textVisibility}
        textBackground={textBackground}
        height="100%"
        border="none"
      >
        <Section
          backgroundImageMobile={backgroundImageMobile}
          backgroundImageDesktop={backgroundImageDesktop}
          noStandardPadding={true}
          style={{ background: solidColorWithOpacity }} //passing overlay color with opacity to section to get rid of image overflow issue
          overlayOpacity={overlayOpacity}
          height="100%"
          transitionProperty="opacity,background"
          transitionTimingFunction="ease"
          transitionDuration="md"
        >
          {image && (
            <ProportionalBox
              ratio={imageRatio === "square" ? 1 : 0.75}
              height={showText ? "auto" : "100%"}
              className="image-container"
              transitionProperty="height, transform"
              transitionTimingFunction="ease"
              transitionDuration="md"
              overflow="hidden"
            >
              <Box
                className="image"
                height="100%"
                transitionProperty="height, transform"
                transitionTimingFunction="ease"
                transitionDuration="md"
              >
                <StyledImage
                  {...image}
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                  imgStyle={{
                    borderBottomWidth: showText ? "1px" : "0px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "transparent",
                    padding:
                      imageWidth === "full" || !card?.useCard ? "0px" : "24px",
                  }} // hiding gap between image and textBox
                  minHeight={!card?.useCard ? "358px" : "auto"}
                />
              </Box>
              <Box
                transitionProperty="opacity"
                transitionTimingFunction="ease"
                transitionDuration="md"
                position="absolute"
                top={0}
                left={0}
                bottom={0}
                right={0}
                opacity={showGradient ? overlayOpacity : 0}
                background={`linear-gradient(to top, ${bgStartColor} 0%,${bgMidColor} 15%, ${bgEndColor}) 100%`}
              />
            </ProportionalBox>
          )}

          {showText && (title || hasDescription || button) && (
            <TextBox
              transitionProperty="opacity,background"
              transitionTimingFunction="ease"
              transitionDuration="md"
              textAlign={alignText === "center" ? "center" : "left"}
              descriptionRichText={descriptionRichText}
              button={button}
              textClassName="shaved-text"
              className="text-box"
              title={title}
              label={label}
              {...cardTitle}
              height="100%"
              width="100%"
              left="0px"
              bottom="0px"
              mt={-4}
              py={6}
              px={5}
              bg={textBackground === "gradient" ? solidColorWithOpacity : null} //trying to match color to gradient on textBox
            />
          )}
        </Section>
      </StyledCard>
    </StyledLink>
  )
}

export default BigImageCard

BigImageCard.strapiProps = {
  title: PropTypes.string,
  description: PropTypes.shape(RichText.strapiProps),
  image: PropTypes.object.isRequired,
}

BigImageCard.propTypes = {
  ...BigImageCard.strapiProps,
  cardBackground: PropTypes.oneOf(["none", "0", "1"]),
  cardShadow: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment BigImageCard on Strapi_ComponentMoleculesBigImageCard {
    title
    label
    backgroundImageMobile {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    backgroundImageDesktop {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    descriptionRichText {
      ...RichText
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    link {
      ...Link
    }
    button {
      ...Button
    }
  }
`
