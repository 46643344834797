import React from "react"
import Svg from "../../utils/Svg"
import Path from "../../utils/Path"
import Rect from "../../utils/Rect"
import G from "../../utils/SvgGroup"

const FacebookIconSvg = ({ color = "primary", bg = "#f5f9f9", ...rest }) => {
  return (
    <Svg
      height="33"
      viewBox="0 0 33 33"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <G fill="none" fill-rule="evenodd">
        <Rect fill={bg} height="33" rx="16.5" width="33" />
        <Path
          d="m14.6470588 25v-7.4375h-2.6470588v-3.0104167h2.6470588v-2.390625c0-2.65624997 1.5882353-4.1614583 3.9705883-4.1614583 1.1470588 0 2.3823529.17708333 2.3823529.17708333v2.65624997h-1.3235294c-1.3235294 0-1.7647059.796875-1.7647059 1.6822917v1.9479167h2.9117647l-.4411765 3.0989583h-2.4705882v7.4375z"
          fill={color}
          fill-rule="nonzero"
        />
      </G>
    </Svg>
  )
}

export default FacebookIconSvg
