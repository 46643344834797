import React from "react"
import Svg from "../../utils/Svg"
import Path from "../../utils/Path"

const LinkedInSvg = ({ color = "primary", bg = "#f5f9f9", ...rest }) => {
  return (
    <Svg
      height="33"
      viewBox="0 0 33 33"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" fill-rule="evenodd">
        <Path
          d="m22.9832917 22.4868333h-2.5174167v-3.9447083c0-.9406667-.019125-2.1512083-1.3118333-2.1512083-1.3125417 0-1.513 1.0235416-1.513 2.0817916v4.014125h-2.5174167v-8.1118333h2.41825v1.1057083h.0325833c.337875-.6375 1.1595417-1.3104166 2.3870834-1.3104166 2.5507083 0 3.0224583 1.67875 3.0224583 3.8639583v4.4525833zm-10.7029167-9.2217916c-.8103333 0-1.4612917-.6559167-1.4612917-1.4627084 0-.8060833.6516667-1.4612916 1.4612917-1.4612916.8075 0 1.462.6552083 1.462 1.4612916 0 .8067917-.6552083 1.4627084-1.462 1.4627084zm1.26225 9.2217916h-2.5245v-8.1118333h2.5245zm10.7000833-14.4868333h-14.48824997c-.69345833 0-1.25445833.54825-1.25445833 1.22470833v14.55058337c0 .6771666.561 1.2247083 1.25445833 1.2247083h14.48612497c.69275 0 1.2594167-.5475417 1.2594167-1.2247083v-14.55058337c0-.67645833-.5666667-1.22470833-1.2594167-1.22470833z"
          fill={color}
          fill-rule="nonzero"
        />
      </g>
    </Svg>
  )
}

export default LinkedInSvg
