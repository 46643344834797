import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import useBreakpoint from "../../hooks/use-breakpoint"
import styled from "styled-components"
import { space, border, compose } from "styled-system"

import css from "../../lib/styled-system/css"
import Link from "../utils/Link"

import Box from "../atoms/Box"
import Card from "../atoms/Card"
import { Paragraph } from "../atoms/Typography"

const CareerListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${compose(space, border)}
`

const StyledLink = styled(Link)`
  &:hover {
    & span {
      ${css({ color: "primary" })}
    }
  }
`

const CareerPosition = ({
  title,
  titleColor,
  department,
  location,
  card,
  link,
  idx,
}) => {
  const sm = useBreakpoint("sm")
  const noCard = card?.useCard === false
  return (
    <StyledLink optional {...link}>
      <Card {...card} my={{ _: !noCard ? 4 : 0 }}>
        <CareerListItem
          py={{ _: 5, md: 6 }}
          pl={{ _: 4, md: !noCard ? 9 : 0 }}
          pr={{ _: 4, md: 0 }}
          borderWidth={noCard ? "1px" : null}
          borderBottom={noCard ? "solid" : null}
          borderTop={noCard && idx === 0 ? "solid" : null}
          borderTopColor="border.0"
          borderBottomColor="border.0"
        >
          <Box flexBasis={{ _: "60%", sm: "33%" }} pr={5}>
            <Paragraph as="span" fontWeight="semibold" color={titleColor}>
              {title}
            </Paragraph>
            {department && sm && (
              <Paragraph as="span" display="block">
                {department}
              </Paragraph>
            )}
          </Box>
          {department && !sm && (
            <Box flexBasis="33%" pr={5}>
              <Paragraph as="span">{department}</Paragraph>
            </Box>
          )}
          {location && (
            <Box flexBasis={{ _: "40%", sm: "33%" }}>
              <Paragraph as="span">{location}</Paragraph>
            </Box>
          )}
        </CareerListItem>
      </Card>
    </StyledLink>
  )
}

CareerPosition.strapiProps = {
  title: PropTypes.string.isRequired,
  department: PropTypes.string,
  location: PropTypes.string,
  link: PropTypes.shape(Link.strapiProps),
}

CareerPosition.propTypes = {
  idx: PropTypes.number,
  ...CareerPosition.strapiProps,
}

export default CareerPosition

export const query = graphql`
  fragment CareerPosition on Strapi_ComponentMoleculesCareerPosition {
    title
    department
    location
    link {
      ...Link
    }
  }
`
