import React from "react"
import Svg from "../../utils/Svg"
import Path from "../../utils/Path"
import Rect from "../../utils/Rect"

const TwitterSvg = ({ color = "primary", bg = "#f5f9f9", ...rest }) => {
  return (
    <Svg
      height="33"
      viewBox="0 0 33 33"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g fill="none" fill-rule="evenodd">
        <Rect fill={bg} height="33" rx="16.5" width="33" />
        <Path
          d="m25.5 10.7723343c-.6760563.3025937-1.3943662.518732-2.1126761.6051873.7605634-.4755043 1.3521127-1.2103746 1.6056338-2.07492794-.7183098.43227666-1.5211267.73487034-2.3239436.90778094-.6760564-.73487028-1.6478873-1.2103746-2.7042254-1.2103746-2.028169 0-3.6760563 1.685879-3.6760563 3.8040346 0 .3025936.0422535.6051873.084507.8645533-3.084507-.1729107-5.7887324-1.6426513-7.60563377-3.97694525-.33802817.56195965-.50704225 1.21037465-.50704225 1.90201725 0 1.29683.67605634 2.463977 1.64788732 3.1556196-.59154929 0-1.18309859-.1729106-1.69014084-.4755043v.0432277c0 1.8587896 1.26760563 3.3717579 2.95774644 3.7175792-.2957746.0864554-.6338028.129683-.9718309.129683-.25352117 0-.46478878 0-.71830991-.0432276.46478874 1.5129683 1.85915491 2.5936599 3.46478871 2.6368876-1.2676056.9942363-2.8732394 1.5994236-4.56338026 1.5994236-.29577464 0-.59154929 0-.88732394-.0432277 1.64788732 1.0806917 3.5915493 1.685879 5.6619718 1.685879 6.8028169 0 10.5211268-5.7492795 10.5211268-10.7636888 0-.1729106 0-.3458213 0-.4755043.7183099-.5619596 1.3521127-1.2536023 1.8169014-1.9884726z"
          fill={color}
          fill-rule="nonzero"
        />
      </g>
    </Svg>
  )
}

export default TwitterSvg
