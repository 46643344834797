import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Filter from "../atoms/Filter"

import FilteredGrid from "../molecules/FilteredGrid"
import TeamCard from "../molecules/TeamCard"
import SectionTitle from "../molecules/SectionTitle"

const TeamGrid = ({
  section,
  sectionTitle,
  flexGrid,
  card,
  teamCards,
  imageShape,
  showNameOnHover,
  align,
  filter,
  teamGridImageRatio,
  imageRatio,
  teamGridImageWidth,
  imageWidth,
}) => {
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        flexGrid={flexGrid}
        filter={filter}
        card={{
          theme: section.theme,
          bg: section.bg,
          borderRadius: "md",
          // boxShadow: "md",
          boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
        }}
      >
        {teamCards &&
          teamCards.map((person, i) => {
            return (
              <TeamCard
                key={i}
                {...person}
                card={card}
                align={align}
                imageShape={imageShape}
                showNameOnHover={showNameOnHover}
                imageRatio={teamGridImageRatio || imageRatio}
                imageWidth={teamGridImageWidth || imageWidth}
              />
            )
          })}
      </FilteredGrid>
    </Section>
  )
}

export default TeamGrid

TeamGrid.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  imageShape: PropTypes.oneOf(["default", "square", "circle"]),
  showNameOnHover: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  filter: PropTypes.shape(Filter.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  teamCards: PropTypes.arrayOf(PropTypes.shape(TeamCard.strapiProps)),
}

TeamGrid.propTypes = {
  ...TeamGrid.strapiProps,
}

export const query = graphql`
  fragment TeamGrid on Strapi_ComponentSectionsTeamGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    teamCards {
      ...TeamCard
    }
    filter {
      ...Filter
    }
    imageShape
    showNameOnHover
    align
    teamGridImageRatio: imageRatio
    teamGridImageWidth: imageWidth
  }
`
