import React from "react"
import { graphql } from "gatsby"

import Layout from "../page/Layout"
import SEO from "../page/Seo"
import Sections from "../page/Sections"

const Page = ({ data }) => {
  const site = data.strapi.site
  const page = data.strapi.site.pages[0]

  const headerHeight = 80
  const isTransparentNav =
    page.transparentNav && page?.hideHeader !== "yes" ? true : false

  const transNavFirstSectionStyles = {
    top: `-${headerHeight}px`,
    left: "0px",
    right: "0px",
    pt: `${headerHeight}px`,
  }
  let firstSectionStyling = {
    theme: "default",
    bg: 0,
  }
  let lastSectionStyling = {
    theme: "default",
    bg: 0,
  }

  const visibleSectionsWithStyles = page.sections.filter(
    currSection => currSection.section && !currSection.section.hidden
  )
  const firstSection = visibleSectionsWithStyles[0]
  const lastSection =
    visibleSectionsWithStyles[visibleSectionsWithStyles.length - 1]

  if (firstSection) {
    firstSectionStyling.theme = firstSection.section.theme
    firstSectionStyling.bg = firstSection.section.bg
    firstSectionStyling.sectionBreak = firstSection.section.sectionBreak
  }
  if (lastSection) {
    lastSectionStyling.theme = lastSection.section.theme
    lastSectionStyling.bg = lastSection.section.bg
    lastSectionStyling.sectionBreak = lastSection.section.sectionBreak
  }

  let prevSection = null
  let visibleSections = page.sections.filter(sec => !sec.section?.hidden)

  const footerStyle = {
    marginTop:
      isTransparentNav && visibleSections.length === 1
        ? `-${headerHeight}px !important`
        : null,
  }

  return (
    <Layout
      site={site}
      firstSectionStyling={firstSectionStyling}
      lastSectionStyling={lastSectionStyling}
      isTransparentNav={isTransparentNav}
      footerStyle={footerStyle}
    >
      <SEO
        socialShare={site.socialShareImage}
        favicon={site.favicon}
        metaDescription={site.siteDescription}
        siteTitle={site.title}
        title={page.title}
        keywords={page.keywords}
        pageDescription={page.pageDescription}
      />

      {visibleSections.map((section, i) => {
        const key = `${section.__typename}_${section.id}`
        const lastSectionIndex = visibleSections.length - 1
        prevSection = visibleSections[i - 1]?.section

        const isFirstSection = i === 0
        const isSecondSection = i === 1
        const lastSectionHasBreak =
          section?.section?.sectionBreak === "slope" && i === lastSectionIndex
        const prevSectionHasBreak =
          !isFirstSection &&
          prevSection &&
          prevSection?.sectionBreak === "slope"

        const transNavSectionStyling =
          isFirstSection && isTransparentNav ? transNavFirstSectionStyles : {}

        const sectionPreviewStyling = {
          zIndex: lastSectionIndex - i + 1,
          mt: prevSectionHasBreak
            ? "-81px !important"
            : isTransparentNav && isSecondSection
            ? `-${headerHeight}px !important`
            : null, //hide white space between sections
          pt: prevSectionHasBreak ? `calc(81px + 80px) !important` : null,
          pb: section?.section?.sectionBreak ? `80px ` : null,
          mb: lastSectionHasBreak ? "80px !important" : null,
          ...transNavSectionStyling,
        }

        return (
          <Sections
            section={{
              ...section,
              section: {
                ...section.section,
                prevSection: prevSection,
                ...sectionPreviewStyling,
              },
            }}
            key={key}
          />
        )
      })}
    </Layout>
  )
}

export default Page

export const query = graphql`
  fragment Page on Strapi_Page {
    title
    slug
    transparentNav
    hideHeader
    hideFooter
    keywords
    pageDescription
    sections {
      ...Sections
    }
  }
`
