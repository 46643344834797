import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import SocialIcons from "../icons/social_icons/Index"
import Link from "./Link"
import ThemeSwitcher from "./ThemeSwitcher"

import Box from "../atoms/Box"

const capitalize = string => {
  if (!string) return ""
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

const StyledLink = styled(Link)`
  & {
    & div:hover {
      opacity: 0.7 !important;
    }
  }
`

const SocialLink = ({
  iconName,
  socialUrl,
  theme,
  bg,
  color,
  iconStyle = "outline", //"solid","outline","none"
  ...rest
}) => {
  const link = {
    // to: -1,
    // sectionId: "",
    href: `${socialUrl}`,
    linkText: `${iconName}`,
    linkOpenTarget: "newTab",
  }
  const componentName = capitalize(iconName) || "Facebook"
  const SvgIcon = SocialIcons[componentName]

  return (
    <ThemeSwitcher theme={theme}>
      <StyledLink {...link} optional>
        <Box
          display="inline-block"
          width={iconStyle === "outline" ? "36px" : "33px"}
          height={iconStyle === "outline" ? "36px" : "33px"}
          bg={iconStyle === "solid" ? `background.${bg}` : "transparent"}
          borderWidth={iconStyle === "outline" ? `2px` : null}
          borderColor={iconStyle === "outline" ? `background.${bg}` : null}
          borderStyle={iconStyle === "outline" ? "solid" : null}
          borderRadius="50%"
          style={{
            transform: iconStyle === "outline" ? "scale(0.92)" : "scale(1)",
          }}
          transitionProperty="opacity"
          transitionTimingFunction="ease"
          transitionDuration="fast"
          {...rest}
        >
          <SvgIcon
            color={iconStyle === "solid" ? color : `background.${bg}`}
            theme={theme}
          />
        </Box>
      </StyledLink>
    </ThemeSwitcher>
  )
}

export default SocialLink

SocialLink.strapiProps = {
  id: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  socialUrl: PropTypes.string,
  color: PropTypes.oneOf(["text", "primary", "secondary"]),
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]),
  bg: PropTypes.number,
  iconStyle: PropTypes.oneOf(["outline", "solid"]),
}

SocialLink.propTypes = {
  ...SocialLink.strapiProps,
}

export const query = graphql`
  fragment SocialLink on Strapi_ComponentUtilsSocialLink {
    iconName
    socialUrl
  }
  fragment SocialLinkStyles on Strapi_ComponentUtilsSocialLinkStyles {
    iconStyle
    color
    theme
    bg
  }
`
