import Facebook from "./Facebook"
import Instagram from "./Instagram"
import Linkedin from "./Linkedin"
import Twitter from "./Twitter"

export default {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
}
