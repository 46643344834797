import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import useBreakpoint from "../../hooks/use-breakpoint"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"

import PricingGridTier from "../molecules/PricingGridTier"
import PricingGridCategory from "../molecules/PricingGridCategory"
import SectionTitle from "../molecules/SectionTitle"

import Pricing from "../sections/Pricing"

const PricingGrid = ({
  section,
  sectionTitle,
  sectionButton,
  flexGrid,
  tiers,
  category,
  largeTextColor,
  gridLargeTextColor,
  tiersAlign,
  gridTiersAlign,
  card,
  lineHeight,
}) => {
  const mobile = useBreakpoint("md")
  const [styleFraction, setStyleFraction] = useState(null)
  useEffect(() => {
    setStyleFraction("1fr ".repeat(tiers?.length + 1))
  }, [tiers?.length])

  const StyledBoxGrid = styled(Box)`
    display: grid;
    grid-template-columns: ${props => {
      return props.tiersNum > 0 ? styleFraction : "1fr"
    }};
    column-gap: 10px;
  `
  const StyledWrapper = styled(Box)`
    &&& {
      border-left: 1px solid rgba(128, 128, 128, 0.25);
      border-bottom: 1px solid rgba(128, 128, 128, 0.25);
    }
  `
  const categories = []
  const subCategoriesList = []
  let startingIndex = 0
  category?.map(item => {
    categories.push(
      <PricingGridCategory
        sectionTheme={section.theme}
        hasButton={sectionButton ? true : false}
        startingIndex={startingIndex}
        tiersData={tiers}
        columns={tiers.length}
        display="grid"
        gridColStyle={styleFraction}
        subCategories={item.subCategory}
        lineHeight={lineHeight}
        {...item}
      />
    )
    startingIndex += item.subCategory.length
    item.subCategory.map(each => {
      subCategoriesList.push(each)
    })
  })
  const makeButtons = () => {
    const buttons = []
    for (let j = 0; j < tiers?.length; j++) {
      if (tiers[j].button) {
        buttons.push(
          <Flex
            height={5}
            alignItems={"center"}
            style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
            bg={"background.0"}
          >
            <Button mx={"auto"} {...tiers[j].button} mb={3} />
          </Flex>
        )
      } else {
        buttons.push(
          <Flex
            justifyContent={"center"}
            height={"100%"}
            style={{ borderRight: "1px solid rgba(128, 128, 128, 0.25)" }}
            bg={"background.0"}
          >
            {/*placeholder div*/}
          </Flex>
        )
      }
    }
    return [buttons]
  }

  if (mobile) {
    //returns normal pricing section for tablet && mobile breakpoint
    const newList = []
    tiers?.map((tier, index) => {
      newList[index] = []
      if (
        tier.selectCategory?.length === undefined ||
        tier.selectCategory?.length === null
      )
        return null
      for (let i = 0; i < tier.selectCategory?.length; i++) {
        if (tier.selectCategory[i].boolean === true) {
          newList[index].push({
            __component: "utils.text",
            text: `${subCategoriesList[i].text}`,
          })
        } else if (tier.selectCategory[i].text) {
          newList[index].push({
            __component: "utils.text",
            text: `${
              subCategoriesList[i].text + ": " + tier.selectCategory[i].text
            }`,
          })
        }
      }
    })

    return (
      <Pricing
        tiersAlign={tiersAlign || gridTiersAlign}
        sectionTitle={sectionTitle}
        section={section}
        flexGrid={flexGrid}
        fromGrid={true}
        categories={subCategoriesList}
        tiers={tiers}
        card={card}
        list={newList}
      />
    )
  }
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      {sectionButton && (
        <Flex mb={7} flexDirection="column" alignItems="center">
          <Button {...sectionButton} />
        </Flex>
      )}

      <StyledBoxGrid mb={6} tiersNum={tiers?.length}>
        <Box className="placeholder" />
        {tiers?.map((tier, index) => {
          return (
            <PricingGridTier
              {...tier}
              largeTextColor={largeTextColor || gridLargeTextColor}
              align={tiersAlign}
              card={card}
              index={index}
            />
          )
        })}
      </StyledBoxGrid>
      {categories}
      <StyledWrapper
        display="grid"
        alignItems="center"
        style={{
          gridColumnStart: 1,
          gridColumnEnd: -1,
          gridTemplateColumns: styleFraction,
        }}
      >
        <Flex
          borderRight={"1px solid rgba(128, 128, 128, 0.25)"}
          height={"100%"}
          bg={"background.0"}
        >
          {/*placeholder div*/}
        </Flex>
        {makeButtons()}
      </StyledWrapper>
    </Section>
  )
}

export default PricingGrid

PricingGrid.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  tiers: PropTypes.arrayOf(PropTypes.shape(PricingGridTier.strapiProps)),
  tiersAlign: PropTypes.oneOf(["left", "center"]).isRequired,
  largeTextColor: PropTypes.oneOf(["text", "primary", "secondary"]).isRequired,
}

export const query = graphql`
  fragment PricingGrid on Strapi_ComponentSectionsPricingGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    sectionButton {
      ...Button
    }
    tiers {
      ...PricingGridTier
    }
    category {
      ...PricingGridCategory
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    lineHeight
    gridLargeTextColor: largeTextColor
    gridTiersAlign: tiersAlign
  }
`
