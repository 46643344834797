import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { compareDesc } from "date-fns"

import Reveal from "../utils/Reveal"

import Button from "../atoms/Button"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import Filter from "../atoms/Filter"
import FlexGrid from "../atoms/FlexGrid"
import Section from "../atoms/Section"

import FilteredGrid from "../molecules/FilteredGrid"
import Post from "../molecules/Post"
import SectionTitle from "../molecules/SectionTitle"

const NUM_TO_LOAD = 6

const defaultCard = {
  borderRadius: "md",
  boxShadow: "md",
  bg: 0,
  theme: "default",
  useCard: true,
}

const BlogGrid = ({
  section,
  sectionTitle,
  flexGrid,
  posts,
  card,
  showDate,
  filter,
}) => {
  showDate = showDate === null ? true : showDate //setting default value for older sites
  card = card === null ? defaultCard : card //setting default value for older sites

  const [numLoaded, setNumLoaded] = useState(NUM_TO_LOAD)
  const [allLoaded, setAllLoaded] = useState(posts.length <= NUM_TO_LOAD)

  function loadMore() {
    const newNum = numLoaded + NUM_TO_LOAD
    setNumLoaded(newNum)
    setAllLoaded(posts.length <= newNum)
  }

  const center = sectionTitle.align === "center"
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        flexGrid={flexGrid}
        filter={filter}
        card={{
          theme: section.theme,
          bg: section.bg,
          borderRadius: "md",
          // boxShadow: "md",
          boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
        }}
      >
        {posts
          // .slice() // Copy array so it can be mutated
          // .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)))
          .slice(0, numLoaded)
          .map((post, i) => (
            <Post
              {...post}
              showDate={showDate}
              align={sectionTitle.align}
              key={i}
              card={card}
            />
          ))}
      </FilteredGrid>
      {!allLoaded && (
        <Flex
          mt={8}
          flexDirection="column"
          alignItems={center ? "center" : "flex-start"}
        >
          <Button text="Load More" onClick={loadMore} />
        </Flex>
      )}
    </Section>
  )
}

export default BlogGrid

BlogGrid.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  filter: PropTypes.shape(Filter.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  posts: PropTypes.arrayOf(PropTypes.shape(Post.strapiProps)),
}

export const query = graphql`
  fragment BlogGrid on Strapi_ComponentSectionsBlogGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    posts {
      ...Post
    }
    card {
      ...Card
    }
    filter {
      ...Filter
    }
    showDate
  }
`
