import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"
import { fetchLeverApiJobs } from "../../lib/apis/ats/lever"

import { Title, Label } from "../atoms/Typography"
import Box from "../atoms/Box"
import Button from "../atoms/Button"
import Flex from "../atoms/Flex"
import Section from "../atoms/Section"

import CareerPosition from "../molecules/CareerPosition"
import SectionTitle from "../molecules/SectionTitle"

const Careers = ({
  button,
  card,
  section,
  sectionTitle,
  positionsTitleColor,
  CareersAtsPositionsTitleColor,
  integration,
}) => {
  const [jobsData, setJobData] = useState(null)
  const [loading, setLoading] = useState(null)
  const [message, setMessage] = useState(null)
  const noDataMessage = `There are no job postings right now, but be on the look out as we're
always adding new positions.`

  const fetchData = async companyName => {
    try {
      setLoading(true)
      setMessage(`Loading...`)
      const res = await fetchLeverApiJobs(companyName)
      setJobData(res.data)
      setLoading(false)
      setMessage(null)
      if (!res.data) {
        setMessage(noDataMessage)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const companyName = integration?.data?.companyName
    if (companyName?.length > 0) {
      fetchData(companyName)
    }
  }, [])

  let jobPositions

  jobPositions =
    jobsData &&
    jobsData?.map((department, id) => {
      return (
        <Reveal>
          <Box key={id} mb={5}>
            <Box align={sectionTitle?.align === "center" ? "center" : "left"}>
              {department?.postings[0]?.categories?.department && (
                <Title mb={4}>
                  {department?.postings[0]?.categories?.department}
                </Title>
              )}
            </Box>
            {department?.postings?.map((position, i) => (
              <>
                <Box
                  align={sectionTitle?.align === "center" ? "center" : "left"}
                >
                  {position.categories?.team.localeCompare(
                    department?.postings[i - 1]?.categories?.team
                  ) !== 0 && <Label mb={5}>{position?.categories?.team}</Label>}
                </Box>
                <CareerPosition
                  title={position?.text}
                  titleColor={
                    positionsTitleColor || CareersAtsPositionsTitleColor
                  }
                  location={position?.categories?.location}
                  department={position?.categories?.department}
                  link={{
                    href: position?.hostedUrl,
                    linkText: position?.text,
                  }}
                  card={card}
                  idx={i}
                  key={i}
                />
              </>
            ))}
          </Box>
        </Reveal>
      )
    })

  return (
    <Section {...section}>
      <Box pb={{ _: 0, md: 6 }}>
        {sectionTitle && (
          <Reveal>
            <SectionTitle
              {...sectionTitle}
              maxWidth={42}
              mb={{ _: 6, md: 7 }}
            />
          </Reveal>
        )}

        <Box as="ul" p={0} m={0} mx="auto">
          {message && (
            <Label
              mb={5}
              align={sectionTitle?.align === "center" ? "center" : "left"}
            >
              {message}
            </Label>
          )}
          {jobPositions}
        </Box>

        {button && (
          <Reveal>
            <Flex
              flexDirection="column"
              alignItems="center"
              mt={{ _: 6, md: 7 }}
            >
              <Button {...button} />
            </Flex>
          </Reveal>
        )}
      </Box>
    </Section>
  )
}

Careers.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  positionsTitleColor: PropTypes.oneOf(["text", "primary"]).isRequired,
  button: PropTypes.shape(Button.strapiProps).isRequired,
}

export default Careers

export const query = graphql`
  fragment CareersAts on Strapi_ComponentSectionsCareersAts {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    card {
      ...Card
    }
    careersAtsPositionsTitleColor: positionsTitleColor
    button {
      ...Button
    }
    integration {
      type
      data
    }
  }
`
